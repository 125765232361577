<template>
  <div>
    <v-system-bar></v-system-bar>

    <template v-if="showLogin">
      <user v-if="infoUser" />
      <guest v-else />

      <v-divider></v-divider>
    </template>

    <v-list nav dense>
      <template v-for="(itemMenu, key) in menuMobile">
        <menu-group v-if="itemMenu.child && itemMenu.child.length > 0" :menuData="itemMenu" :key="key" />
        <menu-item v-else :menuData="itemMenu" :key="key" />
      </template>
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import MenuItem from './menu/item';
import MenuGroup from './menu/group';

import user from './user';
import guest from './guest';

export default {
  name: 'HeaderPtaMobile',
  props: {
    showLogin: Boolean
  },
  components: {
    MenuItem,
    MenuGroup,

    user,
    guest,
  },

  computed: {
    ...mapGetters(['infoUser', 'menuMobile'])
  },
}
</script>