<template>
  <v-list-group
    :value="false"
    :prepend-icon="(menuData.icon ? menuData.icon : '')"
  >
    <template v-slot:activator>
      <v-list-item-title>{{ menuData.name }}</v-list-item-title>
    </template>

    <template v-for="(itemMenu, key) in menuData.child">
      <header-mobile-menu-item :menuData="itemMenu" :key="key" />
    </template>
  </v-list-group>
</template>

<script>
import HeaderMobileMenuItem from './item';

export default {
  name: 'HeaderMobileMenuGroup',
  components: {
    HeaderMobileMenuItem
  },

  props: {
    menuData: Object
  },

  data: () => ({
    groupActive: false
  })
};
</script>
