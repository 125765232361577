<template>
  <v-footer dark padless>
    <v-card flat tile width="100%" class="text-center">
      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>PTA Media</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: 'FooterPta'
}
</script>

<style scoped>
</style>
