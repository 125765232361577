<template>
  <v-card>
    <v-card-title>Đăng ký</v-card-title>
    <template v-if="statusSignup !== 2">
      <v-card-text>
        <v-text-field
          label="Tên hiển thị"
          v-model="nameShow"
          :rules="[rules.required]"
          maxlength="25"
          counter
          @keyup.enter="checkAndSubmit()"
        />

        <v-text-field
          label="Tài khoản"
          v-model="username"
          :rules="[rules.required, rules.username]"
          maxlength="20"
          minlength="5"
          counter
          @keyup.enter="checkAndSubmit()"
        />

        <v-text-field
          label="Mật khẩu"
          v-model="password"
          :rules="[rules.required]"
          hint="Mật khẩu phải từ 8 ký tự trở lên"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          maxlength="21"
          minlength="6"
          counter
          @keyup.enter="checkAndSubmit()"
        />

        <v-text-field
          v-model="email"
          :rules="[rules.required, rules.email]"
          label="E-mail"
          @keyup.enter="checkAndSubmit()"
        />

        <v-text-field
          label="Số điện thoại"
          v-model="phone"
          :rules="[rules.required, rules.phone]"
          hint="Sử dụng số điện thoại việt nam"
          prefix="+84 0"
          maxlength="9"
          counter
          @keyup.enter="checkAndSubmit()"
        />
      </v-card-text>

      <v-card-text style="color: red;" v-if="statusSignup === 3">* {{ msgSignup }}</v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="error" text @click="$emit('closeDialogSignup')">Hủy</v-btn>
        <v-btn
          color="success"
          text
          @click="clickSignup()"
          :loading="this.statusSignup === 1"
          :disabled="this.statusSignup === 1 || validateErr"
        >
          <span>Đăng ký</span>
        </v-btn>
      </v-card-actions>
    </template>
    <template v-else>
      <v-card-text>Đăng ký thành công</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="$emit('closeDialogSignup')">Đóng</v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { signup } from "@/helper/user.js";

export default {
  name: "HeaderMobileModalSignup",
  data: () => {
    let ModalSignup = localStorage.getItem("ModalSignup");
    if (ModalSignup) {
      ModalSignup = JSON.parse(ModalSignup);
    } else {
      ModalSignup = {
        nameShow: "",
        username: "",
        email: "",
        phone: "",
      };
    }

    return {
      statusSignup: 0, // 0: Chưa chạy, 1: đang loading, 2: đăng nhập thành công, 3: đăng nhập thất bại
      msgSignup: '',

      showPassword: false,
      showRePassword: false,
      validateErr: true,

      rules: {
        required: (value) => !!value || "Không được để trống",
        username: (value) => {
          const pattern = /^[A-Za-z0-9]{5,20}$/;
          return (
            pattern.test(value) || "Chỉ sử dụng chữ và số và từ 5-20 kí tự"
          );
        },
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Không đúng định dạng";
        },
        phone: (value) => {
          const pattern = /^\d{9}$/;
          return pattern.test(value) || "Không đúng định dạng";
        },
      },

      nameShow: ModalSignup.nameShow,
      username: ModalSignup.username,
      password: "",
      email: ModalSignup.email,
      phone: ModalSignup.phone,
    };
  },
  methods: {
    ...mapActions(["reloadUserInfo"]),
    clickSignup() {
      if (this.statusSignup !== 1) {
        this.statusSignup = 1
        this.msgSignup = ''

        signup({
          data: {
            username: this.username,
            password: this.password,
            name_show: this.nameShow,
            email: this.email,
            num_phone: '0' + this.phone
          },
          done: data => {
            this.reloadUserInfo(() => {
              this.statusSignup = 2
              this.username = this.password = this.nameShow = this.email = this.phone = ""

              setTimeout(() => {
                this.$emit("closeDialogSignup")
                this.statusSignup = 0
              }, 500)
            })
          },
          error: res => {
            if (res.data.msg) {
              this.msgSignup = res.data.msg
            } else {
              this.msgSignup = 'Đã có lỗi xảy ra không thể kết nối đến PTA Media'
            }
            this.statusSignup = 3
            console.log('Đã có lỗi xảy ra', res);
          }
        })
      }
    },
    changeValue() {
      localStorage.setItem(
        "ModalSignup",
        JSON.stringify({
          nameShow: this.nameShow,
          username: this.username,
          email: this.email,
          phone: this.phone,
        })
      );
      this.validateSend();
    },
    validateSend() {
      this.validateErr = true;
      if (
        this.nameShow.length > 0 &&
        this.nameShow.length < 26 &&
        this.username.length > 4 &&
        this.username.length < 20 &&
        this.email.length > 4 &&
        this.phone.length == 9 &&
        this.password.length > 7 &&
        this.password.length < 22
      ) {
        this.validateErr = false;
      }
    },
    checkAndSubmit() {
      if (!this.validateErr) {
        this.clickSignup();
      }
    },
  },

  watch: {
    nameShow() {
      this.changeValue();
    },
    username() {
      this.changeValue();
    },
    email() {
      this.changeValue();
    },
    phone() {
      this.changeValue();
    },
    password() {
      this.validateSend();
    },
  },
};
</script>
