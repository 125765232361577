<template>
  <v-list-item @click="redirect()">
    <v-list-item-icon v-if="menuData.icon">
      <v-icon>{{ menuData.icon }}</v-icon>
    </v-list-item-icon>

    <v-list-item-title>{{ menuData.name }}</v-list-item-title>
    <!-- kiểm tra xem đây là link hay router để chuyển hướng  -->
  </v-list-item>
</template>

<script>
export default {
  name: 'HeaderMobileMenuItem',
  props: {
    menuData: Object
  },
  methods: {
    redirect() {
      if (this.menuData.type && this.menuData.type === 'link') {
        if (this.menuData.address) {
          window.location = this.menuData.address
        }
      } else {
        if (this.menuData.address) {
          let a = this.menuData.address.replace(/^\/*/, '')
          a = a.replace(/\/*$/, '')
          let b = this.$route.path.replace(/^\/*/, '')
          b = b.replace(/\/*$/, '')
          if (a !== b) {
            this.$router.push(this.menuData.address)
          }
        }
      }
    }
  }
};
</script>
