<template>
  <v-card>
    <v-card-title>Chuyển tài khoản</v-card-title>
    <template v-for="(user, index) in listUser">
      <header-mobile-account-info :user="user" :key="index" />
    </template>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="green darken-1" text @click="$emit('closeDialog')">
        <span>Đóng</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

import HeaderMobileAccountInfo from './info'

export default {
  name: 'HeaderMobileAccountManage',
  components: {
    HeaderMobileAccountInfo
  },
  computed: {
    ...mapGetters(['listUser'])
  },
  data: () => ({
    dialogLogout: false
  })
};
</script>
