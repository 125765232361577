import confProject from './project/center'
/*
  * các ứng dụng hợp lệ
  * center: ứng dụng gốc
  * admin: Quản trị hệ thống
  * 4three: công cụ bên thứ 3
  * bible: ứng dụng kinh thánh
  * sort: công cụ rút gọn link
  * coming: Ứng dụng chuẩn bị ra mắt
*/
const version = 'prod'
var ptaHost = 'ptamda.com'

if (version === 'dev') {
  ptaHost = 'http://'+ptaHost
  confProject.host = 'http://'+confProject.host
} else {
  ptaHost = 'https://'+ptaHost
  confProject.host = 'https://'+confProject.host
}

export default {
  version,
  ptaHost,
  router: new URL(window.location.href),
  ...confProject,
}