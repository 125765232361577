import { activeGet, getAllToken } from '@/helper/user';

export default {
  state: {
    tokenSession: '',
    connectPTA: false,
    listUser: [],
    role: [],
    idUserSelect: 0,
    securityInfo: {
      email: [],
      phone: []
    }
  },
  getters: {
    role: state => state.role,
    tokenSession: state => state.tokenSession,
    menuMobile: state => state.menu,
    listUser: state => state.listUser,
    numUser: state => state.listUser.length,
    getSecurityInfo: state => state.securityInfo,
    connectPTA: state => state.connectPTA,
    infoUser: state => {
      if (state.idUserSelect) {
        return state.listUser.find(elm => elm.id === state.idUserSelect)
      } else {
        return false
      }
    },
  },

  mutations: {
    getUserInfo(state, tokenSession) {
      state.tokenSession = tokenSession
      getAllToken({
        data: {
          session: tokenSession
        },
        done: data => {
          state.connectPTA = true
          state.listUser = data.list_user
          state.idUserSelect = data.select
          state.role = data.role

          if (state.idUserSelect) {
            const userInfoNow = state.listUser.find(elm => elm.id === state.idUserSelect)
            if (userInfoNow && userInfoNow.token_status === 1) {
              activeGet({
                done: data1 => {
                  state.securityInfo.email = data1.email
                  state.securityInfo.phone = data1.phone
                }
              })
            }
          }
        }
      })
    },

    reloadUserInfo(state, action=false) {
      getAllToken({
        data: {
          session: state.tokenSession
        },
        done: data => {
          state.listUser = data.list_user
          state.idUserSelect = data.select

          if (state.idUserSelect) {
            const userInfoNow = state.listUser.find(elm => elm.id === state.idUserSelect)
            if (userInfoNow && userInfoNow.token_status === 1) {
              activeGet({
                done: data => {
                  state.securityInfo.email = data.email
                  state.securityInfo.phone = data.phone
                  if (action) {
                    action()
                  }
                },
                error: res => {
                  if (res.data.msg) {
                    alert(res.data.msg)
                  } else {
                    alert('Đã có lỗi xảy ra')
                  }
                  if (action) {
                    action()
                  }
                }
              })
            } else {
              if (action) {
                action()
              }
            }
          } else {
            if (action) {
              action()
            }
          }
        },
        error: res => {
          console.log('Đã có lỗi xảy ra', res)
          if (action) {
            action()
          }
        }
      })
    }
  },

  actions: {
    reloadUserInfo({ commit }, action=false) {
      commit('reloadUserInfo', action)
    }
  }
}