<template>
  <v-menu bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-list-item-avatar v-bind="attrs" v-on="on">
        <other-mobile-avatar />
      </v-list-item-avatar>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <other-mobile-avatar />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Chào bạn</v-list-item-title>
            <v-list-item-subtitle>
              <span>Vui lòng đăng nhập</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <header-tool />
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item>
          <v-list-item-icon>
              <v-simple-checkbox v-model="$vuetify.theme.dark" :ripple="false" />
            </v-list-item-icon>
          <v-list-item-title @click="$vuetify.theme.dark = !$vuetify.theme.dark">Giao diện tối</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import OtherMobileAvatar from '@/components/other/mobile/avatar'
import HeaderTool from './../tool'

export default {
  name: 'HeaderPtaPC',
  components: {
    OtherMobileAvatar,
    HeaderTool
  },
  watch: {
    '$vuetify.theme.dark': {
      handler: function(data) {
        var configTheme = localStorage.getItem('configTheme')
        if (configTheme) {
          configTheme = JSON.parse(configTheme)
          configTheme.themeBlack =  data
        } else {
          configTheme = {
            themeBlack: data
          }
        }
        localStorage.setItem('configTheme', JSON.stringify(configTheme))
      },
      deep: true,
      immediate: true
    },
  },
}
</script>