import Vue from 'vue'
import App from './App.vue'

import Vuetify from 'vuetify/lib/framework';
import VueTheMask from 'vue-the-mask'

import config from '@/config/app'

import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false
Vue.use(Vuetify).use(VueTheMask);

new Vue({
  router: config.router,
  store: config.store,
  vuetify: new Vuetify({
    icons: {
      iconfont: 'mdi',
    },
    theme: { dark: true },
  }),
  render: h => h(App)
}).$mount('#app')
