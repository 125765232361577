<template>
  <v-list nav dense>
    <v-list-item link @click="active = !active">
      <v-list-item-avatar>
        <other-mobile-avatar />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Chào bạn</v-list-item-title>
        <v-list-item-subtitle>Vui lòng đăng nhập</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>{{ "mdi-menu-" + (active ? "up" : "down") }}</v-icon>
      </v-list-item-action>
    </v-list-item>

    <header-tool />

    <template v-if="active">
      <v-list-item>
        <v-list-item-icon>
            <v-simple-checkbox v-model="$vuetify.theme.dark" :ripple="false" />
          </v-list-item-icon>
        <v-list-item-title @click="$vuetify.theme.dark = !$vuetify.theme.dark">Giao diện tối</v-list-item-title>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import HeaderTool from './../tool'

import OtherMobileAvatar from '@/components/other/mobile/avatar'

export default {
  name: "HeaderMobileGuest",
  components: {
    HeaderTool,

    OtherMobileAvatar
  },
  data() {
    return {
      active: false
    }
  },
  watch: {
    '$vuetify.theme.dark': {
      handler: function(data) {
        var configTheme = localStorage.getItem('configTheme')
        if (configTheme) {
          configTheme = JSON.parse(configTheme)
          configTheme.themeBlack =  data
        } else {
          configTheme = {
            themeBlack: data
          }
        }
        localStorage.setItem('configTheme', JSON.stringify(configTheme))
      },
      deep: true,
      immediate: true
    },
  },
};
</script>
