<template>
  <v-list-item>
    <v-list-item-action>
      <v-btn-toggle>
        <v-dialog v-model="dialogLogin" width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" small v-bind="attrs" v-on="on">Đăng nhập</v-btn>
          </template>
          <header-mobile-modal-login @closeDialogLogin="dialogLogin = false" />
        </v-dialog>
        <v-dialog v-model="dialogSignup" width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="success" small v-bind="attrs" v-on="on">Đăng ký</v-btn>
          </template>
          <header-mobile-modal-signup @closeDialogSignup="dialogSignup = false" />
        </v-dialog>
      </v-btn-toggle>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import HeaderMobileModalLogin from './modal/login'
import HeaderMobileModalSignup from './modal/signup'

export default {
  name: "HeaderMobileTool",
  components: {
    HeaderMobileModalLogin,
    HeaderMobileModalSignup
  },
  data: () => ({
    dialogLogin: false,
    dialogSignup: false
  }),
};
</script>
