<template>
  <v-app>
    <header-pta />
    <v-main v-if="tokenSession">
      <v-container v-if="!meta.unConect && !connectPTA">
        <v-row class="text-center">
          <v-col cols="12">
            <v-img
              src="@/assets/image/logo.png"
              class="my-3"
              contain
              height="200"
            />
          </v-col>

          <v-col class="mb-4">
            <h1>Chưa kết nối được đến PTA Media</h1>

            <v-btn color="error" small @click="clearData()">
              Làm mới ứng dụng
              <v-icon right>mdi-eye-refresh-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <router-view v-else />
    </v-main>
    <v-main v-else>
      <v-container>
        <v-row class="text-center">
          <v-col cols="12">
            <v-img
              src="@/assets/image/logo.png"
              class="my-3"
              contain
              height="200"
            />
          </v-col>

          <v-col class="mb-4">
            <h1>Không thể đồng bộ ứng dụng</h1>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <footer-pta />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

import appConfig from '@/config/app'
import HeaderPta from '@/components/header/index'
import FooterPta from '@/components/footer/pta'

export default {
  name: 'App',
  components: {
    HeaderPta,
    FooterPta
  },

  computed: {
    ...mapGetters(['tokenSession', 'connectPTA'])
  },

  data() {
    return {
      meta: {}
    }
  },

  created() {
    this.meta = this.$route.meta
    var configTheme = localStorage.getItem('configTheme')
    if (configTheme) {
      configTheme = JSON.parse(configTheme)
      this.$vuetify.theme.dark = configTheme.themeBlack ? true : false
    }

    var i = {
      session: localStorage.getItem('sessionToken'),
      notsave: false,
      isIndex: false,
      url: new URL(window.location.href),
      redirect() {
        const url = this.url.searchParams.get('url');
        if (url) {
          window.location.href = i.url.origin + url
        } else {
          window.location.href = i.url.origin
        }
      }
    }

    if (i.url.pathname === '/sysn') {
      i.isIndex = true
      i.notsave = (i.url.searchParams.get('nosave') ? true : false)
    }

    if (!i.session) {
      i.session = i.url.searchParams.get('session')
      if (i.isIndex && i.session) {
        if (!i.notsave) {
          localStorage.setItem('sessionToken', i.session)
        }

        this.$store.commit('getUserInfo', i.session)
        i.redirect()
      } else {
        // chuyển sang trang lấy session
        let url = appConfig.ptaHost + '/sysn/get'
        let urlGetSession = new URL(url)
        urlGetSession.searchParams.append('url', i.url.href.replace(i.url.origin, ''));
        urlGetSession.searchParams.append('process', i.url.origin + '/sysn')
        window.location.href = urlGetSession.href
      }
    } else if (i.isIndex) {
      let sessionToken = i.url.searchParams.get('session');
      if (sessionToken) {
        i.session = sessionToken
        if (!i.notsave) {
          localStorage.setItem('sessionToken', i.session)
        }
      }

      i.redirect()
      this.$store.commit('getUserInfo', i.session)
    } else {
      this.$store.commit('getUserInfo', i.session)
    }
  },

  methods: {
    clearData() {
      if (confirm("Bạn có chắc chắn muốn làm mới? Tính năng này sẽ xóa toàn bộ dữ liệu ứng dụng và bạn có thể sẽ phải kết nối, đăng nhập lại.")) {
        global.localStorage.clear();
        window.location.href = '/'
      }
    },
  },

  watch: {
    '$route.meta': {
      handler: function(data) {
        this.meta = data
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped></style>