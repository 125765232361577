import { gethost, getToken, callAjax } from './app'

const urlConnect = {
  signup: '/api/v1/user/signup',
  login: '/api/v1/user/login',
  getAllToken: '/api/v1/user/get',
  select: '/api/v1/user/select',
  logout: '/api/v1/user/logout',
  relogin: '/api/v1/user/reLogin',
  report: '/api/v1/report',
  updateAvatar: '/api/v1/user/update/avatar',
  updateNameShow: '/api/v1/user/update/nameshow',
  activeGet: '/api/v1/user/active/get',
  reSendEmailVerify: '/api/v1/user/resend-email',
  verifyEmail: '/api/v1/user/active/email',
}

export function signup(conf) {
  const token = getToken()
  if (!conf.data) {
    conf.data = {}
  }
  conf.data.session = token.session
  callAjax(gethost() + urlConnect.signup, conf)
}

export function login(conf) {
  const token = getToken()
  if (!conf.data) {
    conf.data = {}
  }
  conf.data.session = token.session
  callAjax(gethost() + urlConnect.login, conf)
}

export function select(conf) {
  const token = getToken()
  if (!conf.data) {
    conf.data = {}
  }
  conf.data.session = token.session
  callAjax(gethost() + urlConnect.select, conf)
}

export function logout(conf) {
  const token = getToken()
  if (!conf.data) {
    conf.data = {}
  }
  conf.data.session = token.session
  callAjax(gethost() + urlConnect.logout, conf)
}

export function relogin(conf) {
  const token = getToken()
  if (!conf.data) {
    conf.data = {}
  }
  conf.data.session = token.session
  callAjax(gethost() + urlConnect.relogin, conf)
}

export function getAllToken(conf) {
  callAjax(gethost() + urlConnect.getAllToken, conf)
}

export function statusTokenName(tokenStatus) {
  return {
    2: 'Phiên đăng nhập đã hết hạn',
    3: 'Tài khoản đã bị đăng xuất',
    4: 'Tài khoản đã bị khóa',
  }[tokenStatus]
}

export function reportError(conf) {  
  const token = getToken()
  if (!conf.data) {
    conf.data = {}
  }
  conf.data.session = token.session
  if (token.token) {
    conf.data.user_token = token.token
  }
  callAjax(gethost() + urlConnect.report, conf)
}

export function updateAvatar(avatar, conf) {
  const token = getToken()
  if (token.token) {
    var formData = new FormData();
    formData.append("session", token.session);
    formData.append("token", token.token);
    formData.append("image", avatar);
    if (!conf.adv) {
      conf.adv = {}
    }
    conf.adv.headers = {
      'Content-Type': 'multipart/form-data'
    }
    conf.data = formData
    callAjax(gethost() + urlConnect.updateAvatar, conf)
  } else {
    return false;
  }
}

export function updateNameShow(conf) {
  const token = getToken()
  if (token.token) {
    if (!conf.data) {
      conf.data = {}
    }
    conf.data.session = token.session
    conf.data.token = token.token 
    callAjax(gethost() + urlConnect.updateNameShow, conf)
  } else {
    return false;
  }
}

export function activeGet(conf) {
  const token = getToken()
  if (token.token) {
    conf.data = {
      session: token.session,
      token: token.token,
    }
    callAjax(gethost() + urlConnect.activeGet, conf)
  } else {
    return false;
  }
}

export function reSendEmailVerify(conf) {
  const token = getToken()
  if (token.token) {
    if (!conf.data) {
      conf.data = {}
    }
    conf.data.session = token.session
    conf.data.token = token.token 
    callAjax(gethost() + urlConnect.reSendEmailVerify, conf)
  } else {
    return false;
  }
}

export function verifyEmail(conf) {
  const token = getToken()
  if (token.token) {
    if (!conf.data) {
      conf.data = {}
    }
    conf.data.session = token.session
    conf.data.token = token.token
    callAjax(gethost() + urlConnect.verifyEmail, conf)
  } else {
    return false;
  }
}
