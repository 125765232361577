<template>
  <v-list nav dense>
    <v-list-item link @click="active = !active">
      <v-list-item-avatar>
        <other-mobile-avatar :url="infoUser.user_info.avatar" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Chào, <span style="text-transform: capitalize">{{ infoUser.user_info.name_show }}</span></v-list-item-title>
        <v-list-item-subtitle v-if="infoUser.token_status !== 1">
          <span>{{ statusTokenName(infoUser.token_status) }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>{{ "mdi-menu-" + (active ? "up" : "down") }}</v-icon>
      </v-list-item-action>
    </v-list-item>

    <header-tool />

    <template v-if="active">
      <v-list-item v-if="infoUser.token_status === 1" @click="redirect('/user/profile')">
        <v-list-item-icon>
          <v-icon>mdi-account-edit-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Thông tin tài khoản</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="infoUser.token_status === 1">
        <v-list-item-icon>
          <v-icon>mdi-account-cog-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Cài đặt</v-list-item-title>
      </v-list-item>

      <v-dialog v-model="dialogReLogin" width="600px" v-if="[2, 3].find(elm => elm === infoUser.token_status)">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-icon>
              <v-icon>mdi-account-reactivate-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Đăng nhập lại</v-list-item-title>
          </v-list-item>
        </template>
        <modal-re-login @closeDialogReLogin="dialogReLogin = false" />
      </v-dialog>

      <!-- Cái này là đăng xuất thật -->
      <v-list-item @click="logout(infoUser.token)" :disabled="disabledLogin">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Đăng xuất</v-list-item-title>
      </v-list-item>

      <v-dialog v-model="dialogManageUser" width="600px" v-if="numUser > 1">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-icon>
              <v-icon>mdi-account-sync-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Chuyển tài khoản</v-list-item-title>
          </v-list-item>
        </template>
        <account-manage @closeDialog="dialogManageUser = false" />
      </v-dialog>

      <v-list-item>
        <v-list-item-icon>
            <v-simple-checkbox v-model="$vuetify.theme.dark" :ripple="false" />
          </v-list-item-icon>
        <v-list-item-title @click="$vuetify.theme.dark = !$vuetify.theme.dark">Giao diện tối</v-list-item-title>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { statusTokenName, logout } from '@/helper/user'

import OtherMobileAvatar from '@/components/other/mobile/avatar'

import HeaderTool from './../tool'

import AccountManage from './account/manage'
import ModalReLogin from './../modal/reLogin'

export default {
  name: "HeaderMobileShowMenu",
  components: {
    OtherMobileAvatar,

    HeaderTool,

    AccountManage,
    ModalReLogin
  },
  computed: {
    ...mapGetters(['infoUser', 'numUser'])
  },
  data: () => ({
    active: false,
    dialogManageUser: false,
    dialogReLogin: false,
    disabledLogin: false,
  }),
  methods: {
    statusTokenName,
    ...mapActions(['clearUser', 'logoutUser', 'reloadUserInfo']),

    logout(token) {
      var reloadLogin = () => {
        this.reloadUserInfo(() => {
          this.disabledLogin = false
        })
      }
      this.disabledLogin = true
      logout({
        data: {
          token
        },
        done: data => {
          this.disabledLogin = false
          reloadLogin()
        },
        error: res => {
          console.log(res)
          this.disabledLogin = false
        }
      })
    },
    redirect(url='/') {
      let a = url.replace(/^\/*/, '')
      a = a.replace(/\/*$/, '')
      let b = this.$route.path.replace(/^\/*/, '')
      b = b.replace(/\/*$/, '')
      if (a !== b) {
        this.$router.push(url)
      }
    }
  },
  watch: {
    '$vuetify.theme.dark': {
      handler: function(data) {
        var configTheme = localStorage.getItem('configTheme')
        if (configTheme) {
          configTheme = JSON.parse(configTheme)
          configTheme.themeBlack =  data
        } else {
          configTheme = {
            themeBlack: data
          }
        }
        localStorage.setItem('configTheme', JSON.stringify(configTheme))
      },
      deep: true,
      immediate: true
    },
  },
};
</script>
