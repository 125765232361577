<template>
  <div>
    <v-app-bar app dark>
      <div class="d-flex align-center" @click="redirectHome()">
        <!-- Nút điều hướng chỉ hiện khi về màn sm -->
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          class="hidden-sm-and-up"
        ></v-app-bar-nav-icon>

        <!-- Logo dự án -->
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="@/assets/image/logo.png"
          transition="scale-transition"
          width="30"
        />

        <!-- Tên dự án khi kích thước > sm -->
        <h2 style="font-weight: normal">PTA {{ appName }}</h2>
      </div>

      <v-spacer></v-spacer>

      <!-- Thiết kế menu cho tablet -->
      <v-app-bar-nav-icon
        @click.stop="drawer1 = !drawer1"
        class="hidden-xs-only hidden-lg-and-up"
      ></v-app-bar-nav-icon>

      <v-card class="hidden-md-and-down">
        <header-pta-PC :showLogin="showLogin" />
      </v-card>
    </v-app-bar>

    <!-- Menu khi click trên mobile -->
    <v-navigation-drawer v-model="drawer" fixed left temporary>
      <header-pta-mobile :showLogin="showLogin" />
    </v-navigation-drawer>
    <v-navigation-drawer v-model="drawer1" fixed right>
      <header-pta-mobile :showLogin="showLogin" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import appConfig from '@/config/app'
import HeaderPtaMobile from "./mobile";
import HeaderPtaPC from "./pc";

export default {
  name: "HeaderPta",
  components: {
    HeaderPtaMobile,
    HeaderPtaPC,
  },

  data: () => ({
    // thuộc tính cho menu kích hoạt
    drawer: false,
    drawer1: false,
    appName: appConfig.name,
    showLogin: (appConfig.noLogin ? false : true)
  }),

  methods: {
    redirectHome() {
      const urlNow = new URL(window.location.href)
      const configNow = (appConfig.listApp[globalThis.appRun] ? appConfig.listApp[globalThis.appRun] : appConfig.listApp.center)
      if (appConfig.version === 'dev') {
        if (configNow.noLogin) {
          window.location.href = 'http://' + configNow.host
        } else {
          if (this.$route.path !== '/') {
            this.$router.push('/')
          }
        }
      } else {
        if (urlNow.host === configNow.host) {
          if (this.$route.path !== '/') {
            this.$router.push('/')
          }
        } else {
          window.location.href = 'https://' + configNow.host
        }
      }
    }
  }
};
</script>
