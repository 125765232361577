<template>
  <v-menu open-on-hover bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-tab v-bind="attrs" v-on="on">{{ menuData.name }}</v-tab>
    </template>

    <v-list>
      <v-list-item v-for="(item, key) in menuData.child" :key="key" link @click="redirect(item)">
        <v-list-item-icon v-if="item.icon">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

export default {
  name: 'HeaderPCMenuGroup',

  props: {
    menuData: Object
  },

  data: () => ({
    groupActive: false
  }),

  methods: {
    redirect(menuData) {
      if (menuData.type && menuData.type === 'link') {
        if (menuData.address) {
          window.location = menuData.address
        }
      } else {
        if (menuData.address) {
          let a = menuData.address.replace(/^\/*/, '')
          a = a.replace(/\/*$/, '')
          let b = this.$route.path.replace(/^\/*/, '')
          b = b.replace(/\/*$/, '')
          if (a !== b) {
            this.$router.push(menuData.address)
          }
        }
      }
    }
  }
};
</script>
