export default [
  {
    path: '/report',
    name: 'report',
    meta: {
      action: 1
    },
    component: () => import('@/views/Shared/Report.vue')
  },
  {
    path: '/donate',
    name: 'donate',
    meta: {
      unConect: true,
      action: 2,
    },
    component: () => import('@/views/Shared/Donate.vue')
  },
  {
    path: '/verify/email/trust',
    name: 'verifyEmailTrust',
    component: () => import('@/views/Shared/verifyEmailTrust.vue')
  },
  {
    path: '/verify/phone/trust',
    name: 'verifyPhoneTrust',
    component: () => import('@/views/Shared/verifyPhoneTrust.vue')
  },
  {
    path: '/user/profile',
    name: 'UserProfile',
    component: () => import('@/views/Shared/Profile.vue')
  },
]