var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-avatar',_vm._g(_vm._b({},'v-list-item-avatar',attrs,false),on),[_c('other-mobile-avatar',{attrs:{"url":_vm.infoUser.user_info.avatar}})],1)]}}])},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('other-mobile-avatar',{attrs:{"url":_vm.infoUser.user_info.avatar}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(_vm.infoUser.user_info.name_show))]),(_vm.infoUser.token_status !== 1)?_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.statusTokenName(_vm.infoUser.token_status)))]):_vm._e()],1)],1),_c('v-list-item',[_c('header-tool')],1)],1),_c('v-divider'),_c('v-list',[(_vm.infoUser.token_status === 1)?_c('v-list-item',{on:{"click":function($event){return _vm.redirect('/user/profile')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-edit-outline")])],1),_c('v-list-item-title',[_vm._v("Thông tin tài khoản")])],1):_vm._e(),(_vm.infoUser.token_status === 1)?_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-cog-outline")])],1),_c('v-list-item-title',[_vm._v("Cài đặt")])],1):_vm._e(),([2, 3].find(function (elm) { return elm === _vm.infoUser.token_status; }))?_c('v-dialog',{attrs:{"width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-reactivate-outline")])],1),_c('v-list-item-title',[_vm._v("Đăng nhập lại")])],1)]}}],null,false,1676544624),model:{value:(_vm.dialogReLogin),callback:function ($$v) {_vm.dialogReLogin=$$v},expression:"dialogReLogin"}},[_c('modal-re-login',{on:{"closeDialogReLogin":function($event){_vm.dialogReLogin = false}}})],1):_vm._e(),_c('v-list-item',{attrs:{"disabled":_vm.disabledLogin},on:{"click":function($event){return _vm.logout(_vm.infoUser.token)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-title',[_vm._v("Đăng xuất")])],1),(_vm.numUser > 1)?_c('v-dialog',{attrs:{"width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-sync-outline")])],1),_c('v-list-item-title',[_vm._v("Chuyển tài khoản")])],1)]}}],null,false,1217560778),model:{value:(_vm.dialogManageUser),callback:function ($$v) {_vm.dialogManageUser=$$v},expression:"dialogManageUser"}},[_c('account-manage',{on:{"closeDialog":function($event){_vm.dialogManageUser = false}}})],1):_vm._e(),_c('v-list-item',[_c('v-list-item-icon',[_c('v-simple-checkbox',{attrs:{"ripple":false},model:{value:(_vm.$vuetify.theme.dark),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme, "dark", $$v)},expression:"$vuetify.theme.dark"}})],1),_c('v-list-item-title',{on:{"click":function($event){_vm.$vuetify.theme.dark = !_vm.$vuetify.theme.dark}}},[_vm._v("Giao diện tối")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }