<template>
  <v-avatar color="grey lighten-2">
    <v-img v-if="url" :src="url"></v-img>
    <v-icon v-else dark>mdi-account-outline</v-icon>
  </v-avatar>
</template>

<script>
export default {
  name: 'OtherMobileAvatar',
  props: {
    url: {
      type: String,
      default: ''
    }
  }
};
</script>
