<template>
  <v-card>
    <v-card-title>Đăng nhập</v-card-title>
    <template v-if="statusLogin !== 2">
      <v-card-text>
        <v-text-field
          label="Tài khoản"
          v-model="username"
          maxlength="20"
          minlength="5"
          counter
          @keyup.enter="checkAndSubmit()"
        />

        <v-text-field
          label="Mật khẩu"
          v-model="password"
          hint="Mật khẩu phải từ 8 ký tự"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          maxlength="21"
          minlength="6"
          counter
          @keyup.enter="checkAndSubmit()"
        />
      </v-card-text>
      <v-card-text style="color: red;" v-if="statusLogin === 3">* {{ msgLogin }}</v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="error" text @click="$emit('closeDialogLogin')">Hủy</v-btn>
        <v-btn color="success" text @click="clickLogin()" :loading="statusLogin === 1" :disabled="statusLogin === 1 || validateErr">
          <span>Đăng nhập</span>
        </v-btn>
      </v-card-actions>
    </template>
    <template v-else>
      <v-card-text>Đăng nhập thành công</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="$emit('closeDialogLogin')">Đóng</v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { login } from '@/helper/user.js';

export default {
  name: "HeaderMobileModalLogin",
  data: () => {
    let ModalLogin = localStorage.getItem('ModalLogin')
    if (ModalLogin) {
      ModalLogin = JSON.parse(ModalLogin)
    } else {
      ModalLogin = {
        username: ''
      }
    }

    return {
      statusLogin: 0, // 0: Chưa chạy, 1: đang loading, 2: đăng nhập thành công, 3: đăng nhập thất bại
      msgLogin: '',

      validateErr: true,
      showPassword: false,
      password: '',
      username: ModalLogin.username,
    }
  },
  methods: {
    ...mapActions(['reloadUserInfo']),
    clickLogin() {
      if (this.statusLogin !== 1) {
        this.statusLogin = 1
        this.msgLogin = ''

        login({
          data: {
            username: this.username,
            password: this.password,
          },
          done: data => {
            this.reloadUserInfo(() => {
              this.statusLogin = 2
              this.username = this.password = ''

              setTimeout(() => {
                this.$emit('closeDialogLogin')
                this.statusLogin = 0
              }, 500)
            })
          },
          error: res => {
            if (res.data.msg) {
              this.msgLogin = res.data.msg
            } else {
              this.msgLogin = 'Đã có lỗi xảy ra không thể kết nối đến PTA Media'
            }
            this.statusLogin = 3
            console.log('Đã có lỗi xảy ra', res);
          }
        })
      }
    },
    changeValue() {
      localStorage.setItem('ModalLogin', JSON.stringify({
          username: this.username
        })
      )
      this.validateSend()
    },
    validateSend() {
      this.validateErr = true;
      if (
        this.username.length > 4 && this.username.length < 20
        && this.password.length > 7 && this.password.length < 22
      ) {
        this.validateErr = false;
      }
    },
    checkAndSubmit() {
      if (!this.validateErr) {
        this.clickLogin()
      }
    }
  },
  watch: {
    username() {
      this.changeValue()
    },
    password() {
      this.validateSend()
    },
  }
};
</script>
