import axios from 'axios';
import config from '@/config/app'

export function mergeStore(store, storePublic) {
  var storeMerge = storePublic
  Object.keys(store).map(key => {
    if (storeMerge[key]) {
      storeMerge[key] = {
        ...storeMerge[key],
        ...store[key]
      }
    } else {
      storeMerge[key] = store[key]
    }
  })
  return storeMerge
}

export function gethost() {
  if (config.version === 'dev') {
    return config.host
  } else {
    return ''
  }
}

export function getToken() {
  var token = {
    session: config.store.getters.tokenSession,
    token: '',
  }
  if (config.store.getters.infoUser) {
    token.token = config.store.getters.infoUser.token
  }
  return token
}

export function callAjax(host, config) {
  if (!config.method) {
    config.method = 'post'
  }
  if (!config.data) {
    config.data = {}
  }
  if (!config.adv) {
    config.adv = {}
  }

  var axiosReq = false
  if (config.method === 'post') {
    axiosReq = axios.post(host, config.data, config.adv)
  } else {
    axiosReq = axios.get(host, config.data, config.adv)
  }

  axiosReq.then(res => {
    if (res.data.status) {
      if (config.done) {
        config.done(res.data)
      }
    } else {
      if (config.error) {
        config.error(res)
      } else {
        console.error("error", res)
      }
    }
  })
  .catch(error => {
    if (config.error) {
      config.error(error)
    } else {
      console.error("error", error)
    }
  })
  .then(() => {
    if (config.always) {
      config.always()
    }
  })
}
