export default [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Center/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      index: 4
    },
    component: () => import('@/views/Center/About.vue')
  },
  {
    path: '/role-active',
    name: 'UpdateRole',
    component: () => import('@/views/Center/GetRole.vue')
  },
]