// lấy icon tại đây: https://pictogrammers.github.io/@mdi/font/6.5.95/
export default [
  {
    name: 'Tin tức',
    icon: 'mdi-newspaper',
    metaActive: 'new',
    address: '/'
  },
  {
    name: 'Dự án',
    icon: 'mdi-application-braces-outline',
    child: [
      {
        name: 'Thánh ca số',
        icon: 'mdi-book-music-outline',
        type: 'link',
        address: 'https://chant.ptamda.com'
      },
      {
        name: 'Kinh thánh số',
        icon: 'mdi-book-open-variant',
        type: 'link',
        address: 'https://bible.ptamda.com'
      },
      // {
      //   name: 'Đố kinh thánh',
      //   icon: 'mdi-puzzle-outline',
      //   type: 'link',
      //   address: 'https://quiz.ptamda.com',
      // },
      // {
      //   name: 'Tìm hội thánh',
      //   icon: 'mdi-church',
      //   type: 'link',
      //   address: 'https://bible.ptamda.com'
      // },
      // {
      //   name: 'Phòng cầu nguyện',
      //   icon: 'mdi-menorah-fire',
      //   type: 'link',
      //   address: 'https://pray.ptamda.com',
      // },
      {
        name: 'Rút gọn link',
        icon: 'mdi-square-edit-outline',
        type: 'link',
        address: 'https://ust.pw',
      },
    ]
  },
  {
    name: '4Three Tool',
    icon: 'mdi-toolbox-outline',
    child: [
      {
        name: 'Tạo Powerpoint',
        icon: 'mdi-file-powerpoint-outline',
        type: 'link',
        address: 'https://4Three.ptamda.com/pptx',
      },
    ]
  },
  {
    name: 'Tài nguyên',
    icon: 'mdi-folder-open-outline',
    type: 'link',
    address: 'https://drive.google.com/drive/folders/1czpzxDPhtzjMw7CJhJei4XuqrHENbf2v?usp=sharing'
  },
  {
    name: 'Giới thiệu',
    icon: 'mdi-help-circle',
    address: '/about'
  },
  {
    name: 'Báo lỗi',
    icon: 'mdi-alert-circle-outline',
    address: '/report'
  },
  {
    name: 'Dâng hiến',
    icon: 'mdi-credit-card',
    address: '/donate'
  },
]