<template>
  <v-card>
    <v-card-title>Đăng nhập lại</v-card-title>
    <template v-if="statusReLogin !== 2">
      <v-card-text>
        <v-container>
          <v-row justify="center">
            <avatar :url="infoUser.user_info.avatar" />
          </v-row>
          <v-row justify="center">
            <v-card-title>{{ infoUser.user_info.name_show }}</v-card-title>
          </v-row>
          <v-row justify="center">
            <v-text-field
              v-model="passwordRelogin"
              color="purple darken-2"
              label="Mật khẩu"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              @keyup.enter="reLoginAction(infoUser.token)"
              required
            />
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-text style="color: red;" v-if="statusReLogin === 3">* {{ msgReLogin }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="red darken-1" text @click="$emit('closeDialogReLogin')">
          <span>Hủy</span>
        </v-btn>
        <v-btn color="green darken-1" text :loading="statusReLogin === 1" :disabled="statusReLogin === 1" @click="reLoginAction(infoUser.token)">
          <span>Đăng nhập</span>
        </v-btn>
      </v-card-actions>
    </template>
    <template v-else>
      <v-card-text>Đăng nhập thành công</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="$emit('closeDialogReLogin')">Đóng</v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { relogin } from '@/helper/user'

import avatar from '@/components/other/mobile/avatar'

export default {
  name: "HeaderMobileModalReLogin",
  components: {
    avatar,
  },
  computed: {
    ...mapGetters(['infoUser'])
  },
  data: () => ({
    statusReLogin: 0, // 0: Chưa chạy, 1: đang loading, 2: đăng nhập thành công, 3: đăng nhập thất bại
    msgReLogin: '',

    showPassword: false,
    passwordRelogin: ''
  }),
  methods: {
    ...mapActions(['reloadUserInfo']),
    reLoginAction(token) {
      if (this.statusReLogin !== 1) {
        this.statusReLogin = 1
        relogin({
          data: {
            token,
            password: this.passwordRelogin
          },
          done: data => {
            this.reloadUserInfo(() => {
              this.reLogin = false
              this.$emit('closeDialogReLogin')
              this.statusReLogin = 2
            })
          },
          error: res => {
            this.statusReLogin = 3
            
            if (res.data.msg) {
              this.msgReLogin = res.data.msg
            } else {
              this.msgReLogin = 'Đã có lỗi xảy ra không thể kết nối đến PTA Media'
            }
            console.log(res)
            this.passwordRelogin = ''
          }
        })
      }
    },
  }
};
</script>
