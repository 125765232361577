import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'

import storePublic from './../store/public'
import routerPublic from './../routes/public'
import routes from './../routes/center'
import menu from './../menu/center'

// ghép store dùng chung với menu
var store = storePublic
store.state.menu = menu

Vue.use(VueRouter).use(Vuex)
export default {
  project: 'center',
  name: 'Media',
  host: 'ptamda.com',

  store: new Vuex.Store(store),
  router: new VueRouter({
    base: process.env.BASE_URL,
    mode: 'history',
    routes: [
      ...routes,
      ...routerPublic,
      {
        path: "*",
        meta: {
          unConect: true,
        },
        component: () => import('@/views/Shared/Error404.vue')
      },
    ]
  }),
}