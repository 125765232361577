<template>
  <v-list-item>
    <v-list-item-avatar>
      <other-mobile-avatar :url="user.user_info.avatar" />
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title style="text-transform: capitalize">{{ user.user_info.name_show }}</v-list-item-title>
      <template v-if="reLogin">
        <v-list-item-subtitle>
          <v-text-field
            v-model="passwordRelogin"
            color="purple darken-2"
            label="Mật khẩu"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            @keyup.enter="reLoginAction(user.token)"
            required
          ></v-text-field>
        </v-list-item-subtitle>
      </template>
      <template v-else>
        <v-list-item-subtitle v-if="user.token_status !== 1 || infoUser.id === user.id">
          <span v-if="user.token_status !== 1">{{ statusTokenName(user.token_status) }}</span>
          <span v-else-if="infoUser.id === user.id">Tài khoản đang sử dụng</span>
        </v-list-item-subtitle>
      </template>

      <v-btn-toggle borderless>
        <v-btn depressed small v-if="infoUser.id !== user.id && user.token_status === 1" @click="select(user.token)" :disabled="actionSelect">
          <span style="color: blue">Chọn</span>
        </v-btn>

        <v-btn depressed small v-if="[2, 3].find(elm => elm === user.token_status)" @click="reLogin ? reLoginAction(user.token) : reLogin = true">
          <span style="color: green">Đăng nhập lại</span>
        </v-btn>

        <v-btn v-if="reLogin" depressed small @click="reLogin = false">
          <span style="color: red">Hủy</span>
        </v-btn>
        <v-btn v-else depressed small @click="logout(user.token)" :disabled="actionLogout">
          <span style="color: red">{{ ([1, 4].find(elm => elm === user.token_status) ? 'Đăng xuất' : 'Xóa') }}</span>
        </v-btn>
      </v-btn-toggle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { statusTokenName, select, logout, relogin } from '@/helper/user'

import OtherMobileAvatar from '@/components/other/mobile/avatar'

export default {
  name: 'HeaderMobileAccountInfo',
  components: {
    OtherMobileAvatar
  },
  props: {
    user: Object
  },
  data: () => ({
    reLogin: false,
    showPassword: false,
    actionSelect: false,
    actionLogout: false,
    actionRelogin: false,
    passwordRelogin: ''
  }),
  computed: {
    ...mapGetters(['infoUser'])
  },
  methods: {
    statusTokenName,
    ...mapActions(['clearUser', 'logoutUser', 'selectUser', 'reloadUserInfo']),
    reLoginAction(token) {
      this.actionRelogin = true
      relogin({
        data: {
          token,
          password: this.passwordRelogin
        },
        done: data => {
          this.reloadUserInfo(() => {
            this.actionRelogin = false
            this.reLogin = false
          })
        },
        error: res => {
          console.log(res)
          this.actionRelogin = false
          this.passwordRelogin = ''
        }
      })
    },
    select(token) {
      this.actionSelect = true
        select({
        data: {
          token
        },
        done: data => {
          this.reloadUserInfo(() => {
            this.actionSelect = false
          })
        },
        error: res => {
          console.log(res)
          this.actionSelect = false
        }
      })
    },

    logout(token) {
      this.actionLogout = true
      logout({
        data: {
          token
        },
        done: data => {
          this.reloadUserInfo(() => {
            this.actionLogout = false
          })
        },
        error: res => {
          console.log(res)
          this.actionLogout = false
        }
      })
    },
  }
};
</script>
