<template>
  <v-tabs v-model="tabNumber">
    <template v-for="(itemMenu, key) in menuMobile">
      <menu-group v-if="itemMenu.child && itemMenu.child.length > 0" :menuData="itemMenu" :key="key" />
      <v-tab v-else :key="key" @click="redirect(itemMenu)">{{ itemMenu.name }}</v-tab>
    </template>

    <template v-if="showLogin">
      <user v-if="infoUser" />
      <guest v-else />
    </template>
  </v-tabs>
</template>

<script>
import { mapGetters } from 'vuex'

import MenuGroup from './menu/group'

import user from './user';
import guest from './guest';

export default {
  name: 'HeaderPtaPC',
  components: {
    MenuGroup,
    user,
    guest
  },

  props: {
    showLogin: Boolean
  },

  computed: {
    ...mapGetters(['infoUser', 'menuMobile'])
  },

  created() {
    switch (globalThis.appRun) {
      case 'center':
      case 'coming': {
        this.tabNumber = 0
        break
      }

      default: {
        this.tabNumber = 1
        break
      }
    }
  },

  data() {
    return {
      tabNumber: 0,
      noChangeIndex: true
    }
  },

  methods: {
    redirect(menuData) {
      if (menuData.type && menuData.type === 'link') {
        if (menuData.address) {
          window.location = menuData.address
        }
      } else {
        if (menuData.address) {
          let a = menuData.address.replace(/^\/*/, '')
          a = a.replace(/\/*$/, '')
          let b = this.$route.path.replace(/^\/*/, '')
          b = b.replace(/\/*$/, '')
          if (a !== b) {
            this.$router.push(menuData.address)
          }
        }
      }
    }
  },

  watch: {
    '$route.meta': {
      handler: function(meta) {
        if (this.noChangeIndex && meta.index) {
          this.tabNumber = meta.index
          this.noChangeIndex = false
        } else if (this.noChangeIndex && meta.action) {
          this.tabNumber = this.menuMobile.length - 3 + Number(meta.action)
          this.noChangeIndex = false
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>